import { locationServicesFormInit } from '../locationServices/locationServices';

export const findClinicFormInit = () => {
    const staticSelectors = {
        componentClassName: '.c-find-the-clinic-video',
    };

    let componentContainer: HTMLElement;

    const constructor = (htmlComponent: HTMLElement) => {
        componentContainer = htmlComponent;
    };

    const init = () => {
        const elements: NodeListOf<HTMLElement> =
            document.querySelectorAll<HTMLElement>(
                staticSelectors.componentClassName
            );
        if (elements.length > 0) {
            elements.forEach(element => {
                constructor(element);
                locationServicesFormInit(element);
            });
        }
    };

    init();
};
