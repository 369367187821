import { locationServicesFormInit } from '../locationServices/locationServices';

export const searchLocationInit = () => {
    const staticSelectors = {
        componentClassName: '.c-search-location',
    };

    const init = (): void => {
        const elements: NodeListOf<HTMLElement> =
            document.querySelectorAll<HTMLElement>(
                staticSelectors.componentClassName
            );

        if (elements.length > 0) {
            elements.forEach(element => {
                locationServicesFormInit(element);
            });
        }
    };

    init();
};
