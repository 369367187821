const _temp0 = require("./pages/clinics/_clinicsList.scss");
const _temp1 = require("./pages/clinics/_modal.scss");
const _temp2 = require("./pages/clinics/_refineSearch.scss");

module.exports = {
  "clinics":   {
    "_clinicsList": _temp0,
    "_modal": _temp1,
    "_refineSearch": _temp2
  }
}