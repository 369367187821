// Entry Point Stylesheet
import './main.scss';

// Imports all SCSS files(Components and Pages)
import './components/**/*.scss';
import './pages/**/*.scss';

// Import Favicon
const faviconLink = document.querySelector(
    "link[rel~='icon']"
) as HTMLLinkElement;
faviconLink.href = '/content/dam/aa-corporate-ous-icl/favicon.svg';

// Import JS for Components
import { findClinicFormInit } from './components/findClinicForm/findClinicForm';
import { searchLocationInit } from './components/searchLocation/searchLocation';
import { clinicsInit } from './pages/clinics/clinics';

declare global {
    interface Window {
        Bus: any; // event bus
        OneTrust: any;
        _tabbyInstances: any; //tabs
        _googleMapInstances: any; // google map instances
    }
}

const init = () => {
    findClinicFormInit();
    searchLocationInit();
    clinicsInit();
};

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
} else {
    init();
}
